import { IGatsbyImageData } from "gatsby-plugin-image";

export namespace Airtable {
  export interface Page {
    Title: string;
    Content: DataLink<Block>[];
    Description: string;
    Hero_Image: DataLink<Media>[];
    Published: boolean;
    Category: string;
    Alternate_Blocks: boolean;
    Page_Template: string;
    Handle: string;
    Last_Edited: Date;
    Created: Date;
  }
  
  export interface Block {
    Admin_Title: string;
    Images?: DataLink<Media>[];
    Image_Placement?: string;
    ID: number;
    Block_Title?: string;
    Block_Subtitle?: string;
    Link_URL?: string;
    Link_Text?: string;
    Text: RichTextField;
    Website___Page: string[];
  }
  
  export interface Media {
    Description: string;
    Media: ImageField;
    Caption: string;
    Website___Block: DataLink<Block>[];
    Website___Page: DataLink<Page>[];
  }
  
  export interface Partner {
    Partner: string;
    Image: ImageField;
    Link: string;
  }
  
  export interface MenuCategory {
    Category: string;
    Links: DataLink<MenuItem>[];
  }

  export interface MenuItem {
    Page_Link?:  DataLink<Page>[];
    Arbitrary_Link?: string;
    Action?: keyof typeof Actions;
    Text: string;
    Open_in_New_Window?: boolean;
  }

  export interface Staff {
    Name: string;
    Role?: string;
    Bio: RichTextField;
    Forest_Resiliency_Program: boolean;
    Image: ImageField;
  }
  
  export interface Wood {
    Product_ID: string;
    Created: Date;
    In_Stock: boolean;
    Site: string;
    Location: string;
    City_of_Origin: string;
    Type: string;
    Surfaced: boolean;
    Sterilized: boolean;
    Air_Dried: boolean;
    Kiln_Dried: boolean;
    Width_Min: number;
    Width_Max: number;
    Width: number;
    Thickness: number;
    Length: number;
    Character_Grade_Discount: number;
    Board_Feet: number;
    Grade_Discount: number;
    Price_per_Board_Foot: number;
    Base_Price: number;
    Price: number;
    Images: ImageField;
    Code: string;
    Species_Link: DataLink<TreeMap>;
    Species: string[];
    Featured: boolean;
    On_Sale: number;

    Flaw_Discount: string;
    Grade_Premium: string;
    Grade_Premium_Number: string;
    Internal_Notes: string;
    Flaw_Discount_Number: string;
    Reserved: boolean;
    Hide: boolean;
    Sold: boolean;
    Stripe__Orders: string[];
  }
  
  export type Date = string;
  
  export interface TreeMap {
    Tree_ID: string;
    Tree_Type: string;
  }
  
  export interface Art {
    ID: string;
    Artist: DataLink<Artist>[];
    Images: DataLink<ArtImage>[];
    In_Stock: boolean;
    Base_Price: number;
    Price: number;
    Type: string;
    Description?: string;
  }

  export interface ArtImage {
    ID: number;
    Images: ImageField;
  }

  export interface Artist {
    Name: string;
    Link_to_Website?: string;
    Bio?: string;
    Products_Art: DataLink<Art>[];
  }

  export const Actions = {
    'Donate': '#donate',
  }
}

export interface DataLink<Type> {
  data: Type;
}

export interface RichTextField {
  childMarkdownRemark: {
    excerpt: string;
    html: string;
  }
}

export interface ImageField {
  localFiles: {
    id: string;
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
      thumbnail?: {
        src: string;
      };
    } 
  }[];
}

export interface Node<Item> {
  data: Item;
}

export enum QueryParams {
  HideDonate = 'no-donate',
  OrderSuccess = 'success',
}

export namespace Shop {
  export enum StorageKeys {
    Cart = 'lumbercycle_cart_items'
  };

  export enum Ids {
    Donation = 'donation',
  }

  export interface CartItem {
    id: string;
    thumbnail?: string;
    subtitle?: string;
    title: string;
    price: number;
    path: string;
  }

  export namespace Art {
    export interface Data {
      meta: {
        artists: Shop.Filters.Options.Checkbox[];
        types: Shop.Filters.Options.Checkbox[];
      };
      products: { nodes: Node<Airtable.Art>[] };
    }
  }

  export namespace Wood {
    export interface Data {
      meta: {
        maxWidth: number;
        minWidth: number;
        maxLength: number;
        minLength: number;
        maxThickness: number;
        minThickness: number;
        maxPrice: number;
        minPrice: number;
        species: Shop.Filters.Options.Checkbox[];
        totalBoardFeet: number;
      };
      products: { nodes: Node<Airtable.Wood>[] };
    }
  }

  export namespace Sorting {
    export enum Types {
      Newest = 'newest',
      PriceHL = 'priceHL',
      PriceLH = 'priceLH',
    }

    export type Key = null | Types;

    export interface Model {
      name: string; 
      prop: keyof Airtable.Wood, 
      direction: 'asc' | 'desc',
    }
  }

  export namespace Filters{
    export namespace Options {
      export interface Checkbox {
        totalCount?: number;
        fieldValue: string;
      }
    
      export interface Range {
        fieldValue: string;
        label: string;
        min: number;
        max: number;
        stepSize: number;
        productKey: keyof Airtable.Wood;
      }
    }

    export enum SizeOptions {
      Width = 'width',
      Length = 'length',
      Thickness = 'thickness',
    }

    export enum Category {
      Artist = 'artist',
      Type = 'type',
      Size = 'size',
      Attributes = 'attributes',
      Species = 'species',
    }

    export interface ArtConfig {
      [Category.Artist]: Model<Type.Checkbox, Airtable.Art>;
      [Category.Type]: Model<Type.Checkbox, Airtable.Art>;
    }

    export interface WoodConfig {
      [Category.Species]: Model<Type.Checkbox, Airtable.Wood>;
      [Category.Attributes]: Model<Type.Checkbox, Airtable.Wood>;
      [Category.Size]: Model<Type.Range, Airtable.Wood>;
    }

    export interface Model<T extends Type, NodeType extends Airtable.Wood | Airtable.Art> {
      name: string;
      options: T extends Type.Checkbox ? Options.Checkbox[] : Options.Range[];
      type: Type;
      default: Value<T>;
      isActive: (
        activeFilters: Shop.Filters.Value<T>, 
        node: Node<NodeType>, 
      ) => boolean;
    }
    
    export type Value<T extends Type> = T extends Type.Checkbox 
      ? string[] 
      : Record<string, [number, number]>;

    export enum Type {
      Checkbox = 'Checkbox',
      Range = 'Range',
    }

    export enum RangeTypes {
      Min = 'min',
      Max = 'max',
    }

    export type State = Record<Shop.Filters.Category, Shop.Filters.Value<any>>;
  }

  export interface Item {
    RecordID: string;
    ID: string;
    Images: ImageField;
    Name: string;
    OnSale: boolean;
    BasePrice: number;
    FinalPrice: number;
    Description?: string;
  }  
}