import React from 'react';
import { StoreProvider } from "./src/context/store-context"
import { inject } from '@vercel/analytics';

import './src/styles/globals.css'
import './src/styles/slider.css'

export const wrapRootElement = ({ element }) => {
  inject();
  
  return <StoreProvider>{element}</StoreProvider>;
}