exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pay-tsx": () => import("./../../../src/pages/pay.tsx" /* webpackChunkName: "component---src-pages-pay-tsx" */),
  "component---src-pages-shop-art-tsx": () => import("./../../../src/pages/shop/art.tsx" /* webpackChunkName: "component---src-pages-shop-art-tsx" */),
  "component---src-pages-shop-index-tsx": () => import("./../../../src/pages/shop/index.tsx" /* webpackChunkName: "component---src-pages-shop-index-tsx" */),
  "component---src-pages-shop-wood-tsx": () => import("./../../../src/pages/shop/wood.tsx" /* webpackChunkName: "component---src-pages-shop-wood-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-wood-tsx": () => import("./../../../src/templates/wood.tsx" /* webpackChunkName: "component---src-templates-wood-tsx" */)
}

