import { Shop } from 'config/types';
import React, { createContext, useEffect, useState } from 'react';
import { isClient } from 'utils/is-client';

interface StoreContextAPI {
  addToCart: (item: Shop.Item, id: string, path: string) => void;
  cartItems: Shop.CartItem[];
  cartIsOpen: boolean;
  setCartIsOpen: (state: boolean) => void;
  removeFromCart: (item: Shop.CartItem) => void;
  resetCart: () => void;
  subtotal: number;
}

const Cache = isClient() ? localStorage : {
  getItem: () => {},
  setItem: () => {},
} as unknown as Storage;

const defaults = {
  cartItems: JSON.parse(Cache.getItem(Shop.StorageKeys.Cart) ?? '[]'),
};

export const getFormattedItem = (item: Shop.Item, id: string, path: string) => ({
    id, // Airtable ID
    path,
    price: item.FinalPrice,
    subtitle: item.ID,
    title: item.Name,
    thumbnail: item.Images?.localFiles[0].childImageSharp.thumbnail?.src,
})

export const StoreContext = createContext(defaults as StoreContextAPI);

export function StoreProvider({ children }: { children: React.ReactChildren }) {
  const [cartItems, setCartItems] = useState<Shop.CartItem[]>(defaults.cartItems);
  const [subtotal, setSubtotal] = useState(0);
  const [cartIsOpen, setCartIsOpen] = useState(false);

  useEffect(() => {
    setSubtotal(cartItems.reduce((acc, item) => acc + item.price, 0))
  }, [cartItems]);

  function addToCart(item: Shop.Item, id: string, path: string) {
    const newCart = [ ...cartItems, formatItem(item, id, path) ];
      
    set(newCart);
  }

  function removeFromCart(item: Shop.CartItem) {
    const newCart = cartItems.filter(cartItem => cartItem.id !== item.id);

    set(newCart);
  }

  function resetCart() {
    set([]);
  }

  function set(newCart: Shop.CartItem[]) {
    setCartItems(newCart);
    Cache.setItem(Shop.StorageKeys.Cart, JSON.stringify(newCart));
  }

  function formatItem(item: Shop.Item, id: string, path: string): Shop.CartItem {
    return getFormattedItem(item, id, path);
  }

  return (
    <StoreContext.Provider
      value={{
        addToCart,
        cartItems,
        cartIsOpen,
        removeFromCart,
        setCartIsOpen,
        resetCart,
        subtotal,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}